<template>
    <!-- Global notification live region -->
    <div aria-live="assertive" class="pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6 z-50">
      <div class="flex w-full flex-col items-center space-y-4 sm:items-end">
        <!-- Notification panel, dynamically displayed when triggered -->
        <transition
          enter-active-class="transform ease-out duration-300 transition"
          enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
          enter-to-class="translate-y-0 opacity-100 sm:translate-x-0"
          leave-active-class="transition ease-in duration-100"
          leave-from-class="opacity-100"
          leave-to-class="opacity-0"
        >
          <div v-if="showNotification" class="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
            <div class="p-4">
              <div class="flex items-center">
                <div class="flex w-0 flex-1 justify-between">
                  <p class="w-0 flex-1 text-sm font-medium text-gray-900">{{ message }}</p>
                </div>
                <div class="ml-4 flex flex-shrink-0">
                  <button
                    type="button"
                    @click="closeNotification"
                    class="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none"
                  >
                    <span class="sr-only">Close</span>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-5 w-5">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </div>
  </template>
  
  <script setup>
  import { ref, watch } from 'vue'
  import { defineProps, defineEmits } from 'vue'
  
  const props = defineProps({
    showNotification: Boolean,
    message: {
      type: String,
      default: 'Notification Text'
    },
    autoHideDuration: {
      type: Number,
      default: 3000 // 3 seconds auto-hide by default
    }
  })
  
  const emit = defineEmits(['update:showNotification'])
  
  function closeNotification() {
    emit('update:showNotification', false)
  }
  
  // Automatically hide the notification after a delay
  watch(() => props.showNotification, (newValue) => {
    if (newValue) {
      setTimeout(() => {
        closeNotification()
      }, props.autoHideDuration)
    }
  })
  </script>
  
  <style scoped>
  /* Add any custom styles if needed */
  </style>