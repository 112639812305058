// colorData.js
import { reactive } from 'vue';

export const response = reactive({
  "nearestColors": {
    "gainsboro": [
      {
        "color": "#e5e7eb",
        "group": "gainsboro",
        "name": "gainsboro-100"
      }
    ],
    "darkgray": [
      {
        "color": "#9ca3af",
        "group": "darkgray",
        "name": "darkgray-100"
      },
      {
        "color": "rgba(156,163,175,.1)",
        "group": "darkgray",
        "name": "darkgray-200"
      },
      {
        "color": "rgba(156,163,175,.05)",
        "group": "darkgray",
        "name": "darkgray-300"
      },
      {
        "color": "rgba(156,163,175,.2)",
        "group": "darkgray",
        "name": "darkgray-400"
      }
    ],
    "white": [
      {
        "color": "#fff",
        "group": "white",
        "name": "white-100"
      },
      {
        "color": "hsla(0,0%,100%,.1)",
        "group": "white",
        "name": "white-200"
      },
      {
        "color": "hsla(0,0%,100%,.05)",
        "group": "white",
        "name": "white-300"
      },
      {
        "color": "hsla(0,0%,100%,.2)",
        "group": "white",
        "name": "white-400"
      },
      {
        "color": "hsla(0,0%,100%,.25)",
        "group": "white",
        "name": "white-500"
      },
      {
        "color": "hsla(0,0%,100%,.6)",
        "group": "white",
        "name": "white-600"
      },
      {
        "color": "hsla(0,0%,100%,0)",
        "group": "white",
        "name": "white-700"
      },
      {
        "color": "hsla(0,0%,100%,.5)",
        "group": "white",
        "name": "white-800"
      },
      {
        "color": "hsla(0,0%,100%,.3)",
        "group": "white",
        "name": "white-900"
      },
      {
        "color": "hsla(0,0%,100%,.7)",
        "group": "white",
        "name": "white-1000"
      },
      {
        "color": "hsla(0,0%,100%,.75)",
        "group": "white",
        "name": "white-1100"
      }
    ],
    "slategray": [
      {
        "color": "#6b7280",
        "group": "slategray",
        "name": "slategray-100"
      },
      {
        "color": "hsla(220,9%,46%,.1)",
        "group": "slategray",
        "name": "slategray-200"
      },
      {
        "color": "hsla(220,9%,46%,.25)",
        "group": "slategray",
        "name": "slategray-300"
      },
      {
        "color": "hsla(220,9%,46%,.2)",
        "group": "slategray",
        "name": "slategray-400"
      }
    ],
    "royalblue": [
      {
        "color": "#2563eb",
        "group": "royalblue",
        "name": "royalblue-100"
      },
      {
        "color": "rgb(112,132,248)",
        "group": "royalblue",
        "name": "royalblue-200"
      },
      {
        "color": "rgba(29,78,216,.5)",
        "group": "royalblue",
        "name": "royalblue-300"
      },
      {
        "color": "rgba(29,78,216,.1)",
        "group": "royalblue",
        "name": "royalblue-400"
      },
      {
        "color": "rgba(37,99,235,.2)",
        "group": "royalblue",
        "name": "royalblue-500"
      },
      {
        "color": "rgba(29,78,216,.75)",
        "group": "royalblue",
        "name": "royalblue-600"
      }
    ],
    "cornflowerblue": [
      {
        "color": "#1d9bf0",
        "group": "cornflowerblue",
        "name": "cornflowerblue-100"
      },
      {
        "color": "#60a5fa",
        "group": "cornflowerblue",
        "name": "cornflowerblue-200"
      },
      {
        "color": "rgb(148,150,252)",
        "group": "cornflowerblue",
        "name": "cornflowerblue-300"
      },
      {
        "color": "rgb(164,164,252)",
        "group": "cornflowerblue",
        "name": "cornflowerblue-400"
      },
      {
        "color": "rgb(156,164,252)",
        "group": "cornflowerblue",
        "name": "cornflowerblue-500"
      },
      {
        "color": "rgba(96,165,250,.1)",
        "group": "cornflowerblue",
        "name": "cornflowerblue-600"
      },
      {
        "color": "rgba(96,165,250,.3)",
        "group": "cornflowerblue",
        "name": "cornflowerblue-700"
      }
    ],
    "black": [
      {
        "color": "#24292f",
        "group": "black",
        "name": "black-100"
      },
      {
        "color": "#000",
        "group": "black",
        "name": "black-200"
      },
      {
        "color": "#1f2937",
        "group": "black",
        "name": "black-300"
      },
      {
        "color": "#111827",
        "group": "black",
        "name": "black-400"
      },
      {
        "color": "rgb(4,5,21)",
        "group": "black",
        "name": "black-500"
      },
      {
        "color": "rgba(17,24,39,.1)",
        "group": "black",
        "name": "black-600"
      },
      {
        "color": "rgba(17,24,39,.05)",
        "group": "black",
        "name": "black-700"
      },
      {
        "color": "rgba(17,24,39,.25)",
        "group": "black",
        "name": "black-800"
      },
      {
        "color": "rgba(0,0,0,.1)",
        "group": "black",
        "name": "black-900"
      },
      {
        "color": "rgba(31,41,55,.4)",
        "group": "black",
        "name": "black-1000"
      },
      {
        "color": "rgba(31,41,55,.8)",
        "group": "black",
        "name": "black-1100"
      },
      {
        "color": "rgba(17,24,39,.8)",
        "group": "black",
        "name": "black-1200"
      },
      {
        "color": "rgba(17,24,39,.9)",
        "group": "black",
        "name": "black-1300"
      },
      {
        "color": "rgba(31,41,55,0)",
        "group": "black",
        "name": "black-1400"
      },
      {
        "color": "rgba(17,24,39,0)",
        "group": "black",
        "name": "black-1500"
      },
      {
        "color": "rgba(17,24,39,.4)",
        "group": "black",
        "name": "black-1600"
      },
      {
        "color": "rgba(31,41,55,.2)",
        "group": "black",
        "name": "black-1700"
      },
      {
        "color": "rgba(0,0,0,.25)",
        "group": "black",
        "name": "black-1800"
      },
      {
        "color": "rgba(0,0,0,.05)",
        "group": "black",
        "name": "black-1900"
      },
      {
        "color": "rgba(0,0,0,.03)",
        "group": "black",
        "name": "black-2000"
      },
      {
        "color": "rgba(0,0,0,.08)",
        "group": "black",
        "name": "black-2100"
      },
      {
        "color": "rgba(17,24,39,.2)",
        "group": "black",
        "name": "black-2200"
      }
    ],
    "lavender": [
      {
        "color": "#e0e7ff",
        "group": "lavender",
        "name": "lavender-100"
      },
      {
        "color": "rgba(224,231,255,.2)",
        "group": "lavender",
        "name": "lavender-200"
      },
      {
        "color": "rgba(224,231,255,0)",
        "group": "lavender",
        "name": "lavender-300"
      }
    ],
    "lightskyblue": [
      {
        "color": "#80caff",
        "group": "lightskyblue",
        "name": "lightskyblue-100"
      },
      {
        "color": "rgba(128,202,255,0)",
        "group": "lightskyblue",
        "name": "lightskyblue-200"
      }
    ],
    "hotpink": [
      {
        "color": "#ff80b5",
        "group": "hotpink",
        "name": "hotpink-100"
      },
      {
        "color": "#f472b6",
        "group": "hotpink",
        "name": "hotpink-200"
      },
      {
        "color": "#ec4899",
        "group": "hotpink",
        "name": "hotpink-300"
      },
      {
        "color": "rgba(244,114,182,.1)",
        "group": "hotpink",
        "name": "hotpink-400"
      },
      {
        "color": "rgba(255,128,181,0)",
        "group": "hotpink",
        "name": "hotpink-500"
      },
      {
        "color": "rgba(244,114,182,.2)",
        "group": "hotpink",
        "name": "hotpink-600"
      }
    ],
    "deeppink": [
      {
        "color": "#ff4694",
        "group": "deeppink",
        "name": "deeppink-100"
      },
      {
        "color": "rgba(255,70,148,0)",
        "group": "deeppink",
        "name": "deeppink-200"
      }
    ],
    "mediumslateblue": [
      {
        "color": "#8b5cf6",
        "group": "mediumslateblue",
        "name": "mediumslateblue-100"
      },
      {
        "color": "#776fff",
        "group": "mediumslateblue",
        "name": "mediumslateblue-200"
      },
      {
        "color": "#6366f1",
        "group": "mediumslateblue",
        "name": "mediumslateblue-300"
      },
      {
        "color": "rgb(100,100,244)",
        "group": "mediumslateblue",
        "name": "mediumslateblue-400"
      },
      {
        "color": "rgba(99,102,241,.1)",
        "group": "mediumslateblue",
        "name": "mediumslateblue-500"
      },
      {
        "color": "rgba(139,92,246,0)",
        "group": "mediumslateblue",
        "name": "mediumslateblue-600"
      },
      {
        "color": "rgba(99,102,241,.2)",
        "group": "mediumslateblue",
        "name": "mediumslateblue-700"
      }
    ],
    "aliceblue": [
      {
        "color": "#eff6ff",
        "group": "aliceblue",
        "name": "aliceblue-100"
      },
      {
        "color": "rgba(239,246,255,0)",
        "group": "aliceblue",
        "name": "aliceblue-200"
      }
    ],
    "blueviolet": [
      {
        "color": "#4f46e5",
        "group": "blueviolet",
        "name": "blueviolet-100"
      },
      {
        "color": "rgba(79,70,229,.1)",
        "group": "blueviolet",
        "name": "blueviolet-200"
      },
      {
        "color": "rgba(79,70,229,.5)",
        "group": "blueviolet",
        "name": "blueviolet-300"
      },
      {
        "color": "rgba(124,58,237,.5)",
        "group": "blueviolet",
        "name": "blueviolet-400"
      },
      {
        "color": "rgba(109,40,217,.5)",
        "group": "blueviolet",
        "name": "blueviolet-500"
      },
      {
        "color": "rgba(126,34,206,.1)",
        "group": "blueviolet",
        "name": "blueviolet-600"
      },
      {
        "color": "rgba(79,70,229,.2)",
        "group": "blueviolet",
        "name": "blueviolet-700"
      },
      {
        "color": "rgba(147,51,234,.2)",
        "group": "blueviolet",
        "name": "blueviolet-800"
      },
      {
        "color": "rgba(79,70,229,.75)",
        "group": "blueviolet",
        "name": "blueviolet-900"
      },
      {
        "color": "rgba(124,58,237,.75)",
        "group": "blueviolet",
        "name": "blueviolet-1000"
      },
      {
        "color": "rgba(109,40,217,.75)",
        "group": "blueviolet",
        "name": "blueviolet-1100"
      }
    ],
    "mediumpurple": [
      {
        "color": "#9089fc",
        "group": "mediumpurple",
        "name": "mediumpurple-100"
      },
      {
        "color": "#818cf8",
        "group": "mediumpurple",
        "name": "mediumpurple-200"
      },
      {
        "color": "#c084fc",
        "group": "mediumpurple",
        "name": "mediumpurple-300"
      },
      {
        "color": "rgba(129,140,248,.1)",
        "group": "mediumpurple",
        "name": "mediumpurple-400"
      },
      {
        "color": "rgba(192,132,252,.1)",
        "group": "mediumpurple",
        "name": "mediumpurple-500"
      },
      {
        "color": "rgba(129,140,248,.3)",
        "group": "mediumpurple",
        "name": "mediumpurple-600"
      },
      {
        "color": "rgba(192,132,252,.3)",
        "group": "mediumpurple",
        "name": "mediumpurple-700"
      }
    ],
    "dodgerblue": [
      {
        "color": "#3b82f6",
        "group": "dodgerblue",
        "name": "dodgerblue-100"
      },
      {
        "color": "rgba(59,130,246,.5)",
        "group": "dodgerblue",
        "name": "dodgerblue-200"
      }
    ],
    "lightgray": [
      {
        "color": "#d1d5db",
        "group": "lightgray",
        "name": "lightgray-100"
      }
    ],
    "whitesmoke": [
      {
        "color": "#f9fafb",
        "group": "whitesmoke",
        "name": "whitesmoke-100"
      },
      {
        "color": "#f3f4f6",
        "group": "whitesmoke",
        "name": "whitesmoke-200"
      },
      {
        "color": "rgba(243,244,246,.1)",
        "group": "whitesmoke",
        "name": "whitesmoke-300"
      }
    ],
    "mediumspringgreen": [
      {
        "color": "#4ade80",
        "group": "mediumspringgreen",
        "name": "mediumspringgreen-100"
      },
      {
        "color": "#22c55e",
        "group": "mediumspringgreen",
        "name": "mediumspringgreen-200"
      },
      {
        "color": "rgba(74,222,128,.1)",
        "group": "mediumspringgreen",
        "name": "mediumspringgreen-300"
      },
      {
        "color": "rgba(34,197,94,.1)",
        "group": "mediumspringgreen",
        "name": "mediumspringgreen-400"
      },
      {
        "color": "rgba(34,197,94,.2)",
        "group": "mediumspringgreen",
        "name": "mediumspringgreen-500"
      }
    ],
    "darkorchid": [
      {
        "color": "#a855f7",
        "group": "darkorchid",
        "name": "darkorchid-100"
      }
    ],
    "salmon": [
      {
        "color": "#f87171",
        "group": "salmon",
        "name": "salmon-100"
      },
      {
        "color": "hsla(0,91%,71%,.1)",
        "group": "salmon",
        "name": "salmon-200"
      },
      {
        "color": "hsla(0,91%,71%,.2)",
        "group": "salmon",
        "name": "salmon-300"
      }
    ],
    "crimson": [
      {
        "color": "#ef4444",
        "group": "crimson",
        "name": "crimson-100"
      },
      {
        "color": "rgba(220,38,38,.5)",
        "group": "crimson",
        "name": "crimson-200"
      },
      {
        "color": "rgba(220,38,38,.1)",
        "group": "crimson",
        "name": "crimson-300"
      },
      {
        "color": "rgba(220,38,38,.2)",
        "group": "crimson",
        "name": "crimson-400"
      },
      {
        "color": "rgba(220,38,38,.75)",
        "group": "crimson",
        "name": "crimson-500"
      }
    ],
    "gold": [
      {
        "color": "#facc15",
        "group": "gold",
        "name": "gold-100"
      },
      {
        "color": "rgba(250,204,21,.1)",
        "group": "gold",
        "name": "gold-200"
      },
      {
        "color": "rgba(250,204,21,.2)",
        "group": "gold",
        "name": "gold-300"
      }
    ],
    "goldenrod": [
      {
        "color": "#eab308",
        "group": "goldenrod",
        "name": "goldenrod-100"
      }
    ],
    "darkslategray": [
      {
        "color": "#374151",
        "group": "darkslategray",
        "name": "darkslategray-100"
      },
      {
        "color": "rgb(60,68,78)",
        "group": "darkslategray",
        "name": "darkslategray-200"
      },
      {
        "color": "rgba(55,65,81,.1)",
        "group": "darkslategray",
        "name": "darkslategray-300"
      },
      {
        "color": "rgba(55,65,81,.4)",
        "group": "darkslategray",
        "name": "darkslategray-400"
      },
      {
        "color": "rgba(55,65,81,.5)",
        "group": "darkslategray",
        "name": "darkslategray-500"
      },
      {
        "color": "rgba(55,65,81,.75)",
        "group": "darkslategray",
        "name": "darkslategray-600"
      }
    ],
    "mintcream": [
      {
        "color": "#f0fdf4",
        "group": "mintcream",
        "name": "mintcream-100"
      }
    ],
    "lightsteelblue": [
      {
        "color": "rgb(186,188,252)",
        "group": "lightsteelblue",
        "name": "lightsteelblue-100"
      },
      {
        "color": "rgb(185,183,218)",
        "group": "lightsteelblue",
        "name": "lightsteelblue-200"
      }
    ],
    "dimgray": [
      {
        "color": "rgb(85,91,104)",
        "group": "dimgray",
        "name": "dimgray-100"
      },
      {
        "color": "rgba(75,85,99,.1)",
        "group": "dimgray",
        "name": "dimgray-200"
      },
      {
        "color": "rgba(75,85,99,.5)",
        "group": "dimgray",
        "name": "dimgray-300"
      },
      {
        "color": "rgba(75,85,99,.75)",
        "group": "dimgray",
        "name": "dimgray-400"
      }
    ],
    "slateblue": [
      {
        "color": "rgb(99,98,221)",
        "group": "slateblue",
        "name": "slateblue-100"
      },
      {
        "color": "rgba(30,64,175,.5)",
        "group": "slateblue",
        "name": "slateblue-200"
      },
      {
        "color": "rgba(30,64,175,.75)",
        "group": "slateblue",
        "name": "slateblue-300"
      }
    ],
    "lightslategray": [
      {
        "color": "rgb(124,132,156)",
        "group": "lightslategray",
        "name": "lightslategray-100"
      }
    ],
    "mediumseagreen": [
      {
        "color": "rgba(16,185,129,.2)",
        "group": "mediumseagreen",
        "name": "mediumseagreen-100"
      }
    ],
    "lightcoral": [
      {
        "color": "rgba(251,113,133,.1)",
        "group": "lightcoral",
        "name": "lightcoral-100"
      }
    ],
    "seagreen": [
      {
        "color": "rgba(21,128,61,.5)",
        "group": "seagreen",
        "name": "seagreen-100"
      },
      {
        "color": "rgba(22,101,52,.5)",
        "group": "seagreen",
        "name": "seagreen-200"
      },
      {
        "color": "rgba(21,128,61,.75)",
        "group": "seagreen",
        "name": "seagreen-300"
      },
      {
        "color": "rgba(22,101,52,.75)",
        "group": "seagreen",
        "name": "seagreen-400"
      }
    ],
    "darkblue": [
      {
        "color": "rgba(67,56,202,.5)",
        "group": "darkblue",
        "name": "darkblue-100"
      },
      {
        "color": "rgba(67,56,202,.1)",
        "group": "darkblue",
        "name": "darkblue-200"
      },
      {
        "color": "rgba(67,56,202,.75)",
        "group": "darkblue",
        "name": "darkblue-300"
      }
    ],
    "mediumvioletred": [
      {
        "color": "rgba(190,24,93,.5)",
        "group": "mediumvioletred",
        "name": "mediumvioletred-100"
      },
      {
        "color": "rgba(157,23,77,.5)",
        "group": "mediumvioletred",
        "name": "mediumvioletred-200"
      },
      {
        "color": "rgba(190,24,93,.1)",
        "group": "mediumvioletred",
        "name": "mediumvioletred-300"
      },
      {
        "color": "rgba(219,39,119,.2)",
        "group": "mediumvioletred",
        "name": "mediumvioletred-400"
      },
      {
        "color": "rgba(190,24,93,.75)",
        "group": "mediumvioletred",
        "name": "mediumvioletred-500"
      },
      {
        "color": "rgba(157,23,77,.75)",
        "group": "mediumvioletred",
        "name": "mediumvioletred-600"
      }
    ],
    "firebrick": [
      {
        "color": "rgba(185,28,28,.5)",
        "group": "firebrick",
        "name": "firebrick-100"
      },
      {
        "color": "rgba(185,28,28,.75)",
        "group": "firebrick",
        "name": "firebrick-200"
      }
    ],
    "peru": [
      {
        "color": "rgba(161,98,7,.5)",
        "group": "peru",
        "name": "peru-100"
      },
      {
        "color": "rgba(161,98,7,.75)",
        "group": "peru",
        "name": "peru-200"
      }
    ],
    "saddlebrown": [
      {
        "color": "rgba(133,77,14,.5)",
        "group": "saddlebrown",
        "name": "saddlebrown-100"
      },
      {
        "color": "rgba(133,77,14,.75)",
        "group": "saddlebrown",
        "name": "saddlebrown-200"
      }
    ],
    "forestgreen": [
      {
        "color": "rgba(22,163,74,.2)",
        "group": "forestgreen",
        "name": "forestgreen-100"
      }
    ],
    "darkgoldenrod": [
      {
        "color": "rgba(202,138,4,.2)",
        "group": "darkgoldenrod",
        "name": "darkgoldenrod-100"
      }
    ]
  },
  "visualPalette": {
    "Logo Colors": [
      {
        "color": "rgb(186, 188, 252)",
        "name": "lightsteelblue"
      },
      {
        "color": "rgb(100, 100, 244)",
        "name": "mediumslateblue"
      },
      {
        "color": "rgb(148, 150, 252)",
        "name": "cornflowerblue"
      },
      {
        "color": "rgb(164, 164, 252)",
        "name": "cornflowerblue"
      },
      {
        "color": "rgb(156, 164, 252)",
        "name": "cornflowerblue"
      },
      {
        "color": "rgb(112, 132, 248)",
        "name": "royalblue"
      }
    ],
    "Homepage Colors": [
      {
        "color": "rgb(187, 186, 222)",
        "name": "lightsteelblue"
      },
      {
        "color": "rgb(4, 5, 21)",
        "name": "black"
      },
      {
        "color": "rgb(84, 90, 103)",
        "name": "dimgray"
      },
      {
        "color": "rgb(100, 99, 215)",
        "name": "slateblue"
      },
      {
        "color": "rgb(124, 132, 147)",
        "name": "lightslategray"
      },
      {
        "color": "rgb(45, 49, 62)",
        "name": "darkslategray"
      },
      {
        "color": "rgb(135, 92, 233)",
        "name": "mediumslateblue"
      },
      {
        "color": "rgb(60, 68, 82)",
        "name": "darkslategray"
      },
      {
        "color": "rgb(46, 48, 95)",
        "name": "darkslateblue"
      }
    ]
  }
});